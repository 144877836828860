import {Component, OnInit} from '@angular/core';
import {Configs} from '~/src/app/configs/configs';
import { socialSiteAppearance } from '../social-media-platforms-config';

@Component({
    selector: 'smd-social-media-platform-chooser',
    templateUrl: './social-media-platform-chooser.component.html',
    styleUrls: ['./social-media-platform-chooser.component.scss']
})
export class SocialMediaPlatformChooserComponent implements OnInit {
    platforms = Configs.socials;

    constructor() {
    }

    ngOnInit() {

        this.platforms = this.platforms.filter(
            (item) => socialSiteAppearance?.[item.id]?.templatemanager !== false
        );

    }
    
}
