import {
    AfterViewInit,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChildren,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView,
} from "angular-calendar";
import * as moment from "moment";
import { cloneDeep, forEach } from "lodash";
import { Subject, Subscription } from "rxjs/index";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { SocialSiteService } from "~/src/app/components/social-site-select/social-site.service";
import {
    BASE_DATETIME_FORMAT,
    SOCIAL_PLATFORMS_DEFAULT_CONFIG,
} from "~/src/app/configs/configs";
import { CustomEvents, DateHelper, Debounce } from "~/src/app/services/helpers";
import {
    PostActions,
    PostInterface,
} from "~/src/app/modules/posts/post-actions";
import { PostActionsService } from "~/src/app/modules/posts/post-actions.service";
import { BottomSheetChooseScheduleSourceComponent } from "~/src/app/modules/posts/schedule/bottom-sheet-choose-schedule-source/bottom-sheet-choose-schedule-source.component";
import { DialogPostsListComponent } from "~/src/app/modules/posts/schedule/dialog-posts-list/dialog-posts-list.component";
import { PostTemplateFiltersComponent } from "~/src/app/modules/posts/post-template-filters/post-template-filters.component";
import { UsersController } from "~/src/app/modules/users/users/users.component";
import { Category } from "~/src/app/modules/posts/template.interface";
import { LanguageService } from "~/src/app/services/language.service";
import { StateSaver } from "~/src/app/services/state-saver";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { CarouselController } from "~/src/app/modules/posts/carousel/carousel.component";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { PostManagerComponent } from "~/src/app/modules/social-media-post/post-manager/post-manager.component";
import { PostTemplateManagerComponent } from "~/src/app/modules/social-media-post/post-template-manager/post-template-manager.component";
import Utils from "~/src/app/core/utils";
import { PaginationController } from "~/src/app/services/pagination.controller";
import { SocialPlatformService } from "~/src/app/shared/components/social-platform-switcher/social-platform-switcher.service";
import { environment } from "~/src/environments/environment";
import { ServerTimeService } from "~/src/app/core/server-time.service";
import { SocialMediaPostService } from "~/src/app/modules/social-media-post/social-media-post.service";
import { LoggedUser } from "~/src/app/services/logged-user";
import {
    POST_SCHEDULE_DATE_DELAY_MINUTES,
    PostFormControlNames,
} from "~/src/app/modules/social-media-post/social-media-post.constant";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { socialSiteAppearance } from "../../social-media-post/social-media-platforms-config";
import { DateSupport } from "~/src/app/core/helper/date-support";
import { PostPreviewComponent } from "../post-previews/post-preview/post-preview.component";
import { OrganizationController } from "~/src/app/components/organization-select/organization.component";
// PostCardClasses moved to post template

declare var $;

@Component({
    selector: "smd-post-calendar",
    templateUrl: "./schedule.component.html",
    styleUrls: ["./schedule.component.scss"],
})
export class ScheduleComponent
    extends PostActions
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChildren(PostTemplateFiltersComponent) filterForm;
    resetForm: Subject<any> = new Subject<any>();
    filterClickEventsSubject: Subject<any> = new Subject();
    filterToggled = false;
    /**
     * for Calendar
     */
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    viewDate = new Date();
    todaysDate = moment(
        moment
            .tz(
                moment().utc(),
                LoggedUser.getUser().settings.defaultTimeZone ||
                    DateSupport.getClientTimeZoneName()
            )
            .format("YYYY-MM-DD HH:mm")
            .toString()
    ).toDate();
    activeDayIsOpen = false;
    actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-fw fa-pencil"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {},
        },
        {
            label: '<i class="fa fa-fw fa-times"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {},
        },
    ];
    events: CalendarEvent[] = [];
    refresh: Subscription;
    _loadingRef: MatDialogRef<DialogLoaderComponent>;
    enableGetPosts = true;
    postEditInProgress = false;
    weekrefreshFlag = false;
    monthrefreshFlag = false;
    categories: Category[] = [];
    refreshSubscription: Subscription;
    _stateManager = null;
    startDate: any;
    endDate: any;
    enableGroupPosts = false;
    contextMenuForNewPostOptions = {
        mouseDownTimeMoment: null,
        requiredMouseDownTimeMs: 900,
        // it is for disable the click function to just open the new post context menu
        isNeedOpenContextMenu: false,
    };
    wasPagination = false;
    enableServerTime = !environment.isSharpMode;
    public socialPlatforms = SOCIAL_PLATFORMS_DEFAULT_CONFIG;
    private isLoaderOpened = false;

    isCalendarView = false;
    isCardListView = false;

    postListRefresh = new Subject();
    subscriptions: Subscription[] = [];
    filtercount=0;
    filtercount1=0;
    activeFilter =false;
    constructor(
        public dialog: MatDialog,
        service: PostActionsService,
        socialService: SocialSiteService,
        private bottomSheet: MatBottomSheet,
        private socialSiteService: SocialSiteService,
        private socialSiteController: SocialSiteController,
        users: UsersController,
        carouselController: CarouselController,
        public serverTimeService: ServerTimeService,
        public organizationController: OrganizationController,
        public openModal: OpenModalService,
        public postService: SocialMediaPostService,
        private socialPlatformService: SocialPlatformService
    ) {
        super(service, dialog, users, carouselController, openModal);

        this.paginationController = new PaginationController();

        this.paginationController.onSetPagination.subscribe((event) => {
            if (this.getActiveTabId() === "#calendar") {
                delete this.filters["limit"];
                delete this.filters["offset"];

                return;
            } else {
                this.filters["offset"] = event.offset;
                this.filters["limit"] = event.limit;
            }
            this.filterForm.first.emitFilterChange();
        });

        this.initSocialSites();

        delete this.filters["limit"];
        delete this.filters["offset"];

        const savedState = localStorage.getItem("scheduleViewChange");

        if (!!savedState) {
            const savedStateID = Utils.lodash.get(
                JSON.parse(savedState),
                "id",
                null
            );

            if (!!savedStateID && savedStateID === "scheduleViewChangeList") {
                this.filters = this.defaultFilters;
            }
        }

        this.refreshSubscription = this.service
            .getRefreshSignal()
            .subscribe(() => {
                this.getPosts();
            });

        this.subscriptions.push(
            this.postService.postListRefresh$.subscribe(() => {
                this.getItems();
            })
        );
    }

    ngOnInit() {
        this._stateManager = new StateSaver();
        this._stateManager.getState("scheduleViewChange");
        this.viewDate = moment(
            moment
                .tz(
                    moment().utc(),
                    LoggedUser.getUser().settings.defaultTimeZone ||
                        DateSupport.getClientTimeZoneName()
                )
                .format("YYYY-MM-DD HH:mm")
                .toString()
        ).toDate();
        //filter out unwanted social types according to the config file (social-media-platform-config)
    }

    ngOnDestroy(): void {
        this.refreshSubscription.unsubscribe();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const activeTabID = this.getActiveTabId();
            if (activeTabID) {
                this.setViewTab(activeTabID, true);
            }
        });
    }

    @HostListener("window:focus")
    onWindowFocus() {
        this.serverTimeService.calculateServerTime();
    }

    onMouseDownDay() {
        this.contextMenuForNewPostOptions.mouseDownTimeMoment = moment();
    }

    onMouseUpDay() {
        const mouseDownMs =
            this.contextMenuForNewPostOptions.mouseDownTimeMoment.valueOf();
        const mouseUpMs = moment().valueOf();

        if (
            mouseUpMs - mouseDownMs >=
            this.contextMenuForNewPostOptions.requiredMouseDownTimeMs
        ) {
            this.contextMenuForNewPostOptions.isNeedOpenContextMenu = true;
        }
    }

    /**
     * Set filters and get posts
     *
     * @param viewRenderEvent
     */
    @Debounce()
    getPosts(): void {
        this.enableGetPosts = false;

        this.getItems();
    }

    /**
     * Refresh posts in calendar
     */
    refreshPosts(): void {
        this.getPosts();
    }

    onPaginateChange(event) {
        this.wasPagination = true;
        this.paginationController.paginateChange(event);
    }

    /**
     * Success get items
     *
     * @param {any} posts
     */
    successGetItems(response): void {
        let { posts } = response;
        const { count } = response;

        if (this.enableGroupPosts) {
            super.successGetItems({ posts, count });
        } else {
            posts = this.postsProcess(posts);
        }

        this.paginationOptions.length = count;

        this.events = [];

        this.posts = posts;

        const events = [];

        posts.forEach((post) => {
            const startDate = moment(post.calendarDate, "YYYY-MM-DD hh:00:00");
            events.push({
                id: post.postID,
                title: post.mainCopy,
                start: startDate.toDate(),
                end: startDate.add(45, "minutes").toDate(),
                meta: {
                    status: post.status,
                    post: post,
                },
                draggable: true,
                cssClass: "cs-event",
            });
        });

        this.events = events;

        this.postListRefresh.next({ events });

        this.stopPreloader();
    }

    /**
     * Calendar event click event
     *
     * @param event
     */
    eventClick(event): void {}

    @Debounce()
    dropPost({
        postID,
        newActiveFrom,
    }: {
        postID: number;
        newActiveFrom: string;
    }) {
        if (!postID) {
            return;
        }

        this.postEditInProgress = true;

        const loader = this.openModal.loader(DialogLoaderComponent);

        loader.afterClosed().subscribe(({ isSuccess, response }) => {
            if (!isSuccess) {
                this.dialog.open(DialogErrorComponent, {
                    data: {
                        message:
                            FormValidationService.readError(response).message,
                    },
                });
            } else {
                this.refreshPosts();
            }
        });

        let post = cloneDeep(
            this.posts.find((_post) => Number(postID) === _post.postID)
        );

        post = this.postService.preparePostForRequest({
            ...post,
            [PostFormControlNames.ActiveFrom]: newActiveFrom,
        });

        this.postService
            .savePost(postID, [post])
            .then((response) => {
                this.postEditInProgress = false;
                loader.close({
                    isSuccess: true,
                    response: response,
                });
            })
            .catch((error) => {
                this.postEditInProgress = false;
                loader.close({
                    isSuccess: false,
                    response: error,
                });
            });
    }

    /**
     * Before calendar view render
     *
     * @param event
     * @param {"month" | "week"} type
     */
    beforeViewRender(event, type: "month" | "week") {
        if (this.postEditInProgress) {
            return;
        }

        const events = event.period.events,
            start = event.period.start,
            end = event.period.end;

        this.startDate = start;
        this.endDate = end;

        // this.filters['activeFrom'] = moment(start).format(BASE_DATETIME_FORMAT);
        // this.filters['activeFromTo'] = moment(end).format(BASE_DATETIME_FORMAT);

        switch (type) {
            case "month":
                if (events.length > 0) {
                    const body = event.body;

                    body.forEach((cell) => {
                        const groups = {};

                        cell.date.getDate() == this.todaysDate.getDate() &&
                        cell.inMonth
                            ? (cell.isToday = true)
                            : (cell.isToday = false);

                        if (!cell["eventGroups"]) {
                            cell.events.forEach((e) => {
                                if (e.meta.post.categories != null) {
                                    groups[e.meta.post.categories[0].name] =
                                        groups[
                                            e.meta.post.categories[0].name
                                        ] || [];
                                    groups[e.meta.post.categories[0].name].push(
                                        e
                                    );
                                }
                            });

                            cell["eventGroups"] = Object.entries(groups);
                        }
                    });
                }

                this.weekrefreshFlag = false;

                if (!this.monthrefreshFlag || this.enableGetPosts) {
                    if(this.filtercount1 !=0){
                        this.refreshPosts();
                    }
                    this.filtercount1++;
                    // this.refreshPosts();
                    this.monthrefreshFlag = true;
                }

                break;

            case "week":
                const $calHeaders = $(".cal-header");
                let i = 0;
                /**
                 * Set a number of events for a day
                 * */
                event.hourColumns.forEach((column: any) => {
                    const eventsCount = column.events.length;
                    $calHeaders.eq(i).find(".badge").text(eventsCount);
                    i++;
                });

                for (const weekEvent of events) {
                    weekEvent.draggable =
                        moment().add(1, "hour").format(BASE_DATETIME_FORMAT) <
                        moment(weekEvent.meta.post.activeFrom).format(
                            BASE_DATETIME_FORMAT
                        );
                }

                this.monthrefreshFlag = false;

                if (!this.weekrefreshFlag || this.enableGetPosts) {
                    this.refreshPosts();
                    this.weekrefreshFlag = true;
                }

                break;
        }
    }

    getCellLengthOfSocialType(cell, social) {
        return cell.events.filter(
            (event) => event.meta.post.socialType === social
        ).length;
    }

    getPlatformCount(cell, social) {
        // get the count of unique platforms for a day
        const platforms = cell.events.map(
            (event) => event.meta.post.socialType
        );
        return Array.from(new Set(platforms)).length;
    }

    /**
     *
     * Drag and drop handler
     */
    eventTimeChange({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {
        const now = moment().format(BASE_DATETIME_FORMAT);
        const newTime = moment(newStart).format(BASE_DATETIME_FORMAT);

        // az új időpont nagyobb mint az aktuális drag és az esemény autofeed akkor cserélhető
        if (moment(newTime).isAfter(now)) {
            event.start = newStart;
            event.end = newEnd;
            // ide jön a post update implementáció (apizás)
            this.refreshPosts();
        }
    }

    /**
     * Schedule post from template or new post
     *
     * @param {MouseEvent} event
     * @param day
     */
    newPostSchedule(event: MouseEvent, day?, postType?: string) {
        const isWeeklyView = !!day;
        let hour = null;

        if ("isEmit" in event) {
            day = { date: event["date"] };
            hour = event["hour"];
            postType = event["postType"];
            event = event["event"];
        }
        event.stopPropagation();

        const date = day ? day.date : "";

        const defaultTimeZone = LoggedUser.getUserDefaultTimeZoneName();
        let fullDate;

        if (isWeeklyView) {
            // weekly view
            fullDate = Utils.moment(date).format(
                BASE_DATETIME_FORMAT.replace(
                    "HH",
                    Utils.moment().format("HH")
                ).replace("mm", Utils.moment().format("mm"))
            );

            fullDate = Utils.moment(fullDate)
                .tz(defaultTimeZone)
                .format(BASE_DATETIME_FORMAT);
        } else {
            // daily view
            fullDate = Utils.moment(day.date).format(
                BASE_DATETIME_FORMAT.replace(
                    "HH",
                    Utils.moment(hour).format("HH")
                ).replace("mm", Utils.moment().format("mm"))
            );
        }

        fullDate = Utils.moment(fullDate).set("date", date.getDate());

        fullDate = CommonPostHelpers.roundMinuteToFive(
            Utils.moment(fullDate)
                .add(POST_SCHEDULE_DATE_DELAY_MINUTES, "minute")
                .format(BASE_DATETIME_FORMAT)
        ).dateTime;

        event.preventDefault();

        if (postType === "template") {
            this.openModal
                .createPostFromTemplate(PostManagerComponent, {
                    data: {
                        scheduleDate: fullDate,
                    },
                })
                .afterClosed()
                .subscribe((enable) => {
                    if (enable) {
                        this.refreshPosts();
                    }
                });
        }

        if (postType === "scratch") {
            this.openModal
                .createPost(PostManagerComponent, {
                    data: {
                        scheduleDate: fullDate,
                    },
                })
                .afterClosed()
                .subscribe((postManagerResult) => {
                    if (postManagerResult) {
                        this.refreshPosts();
                    }
                });
        }
        return false;
    }

    /**
     * Click on day event
     *
     * @param {Date} date
     * @param {CalendarEvent[]} events
     */
    dayClicked(event: MouseEvent, day, socialType?: string): void {
        event.stopPropagation();
        if (!this.contextMenuForNewPostOptions.isNeedOpenContextMenu) {
            if (day.events.length > 0) {
                this.openPostsModal(day, socialType);
            }
        } else {
            this.newPostSchedule(event, day);
            this.contextMenuForNewPostOptions.isNeedOpenContextMenu = false;
        }
    }

    /**
     *
     * @param event
     * @param day
     */
    weekDayClicked(event: MouseEvent, day): void {
        event.stopPropagation();
        const check = moment(day.date, "YYYY/MM/DD");
        const dateYear = check.format("yyyy");
        const dateMonth = check.format("mm");
        const dateDay = check.format("D");

        const currentEvents = [];

        this.events.forEach(function (currentValue) {
            const startDate = moment(
                currentValue.meta.post.activeFrom,
                "YYYY/MM/DD"
            );
            const startDateYear = startDate.format("yyyy");
            const startDateMonth = startDate.format("mm");
            const startDateDay = startDate.format("D");

            if (
                dateYear === startDateYear &&
                dateMonth === startDateMonth &&
                dateDay === startDateDay
            ) {
                currentEvents.push(currentValue);
            }
        });

        const weekDay = {
            date: day.date,
            events: currentEvents,
        };

        this.openPostsModal(weekDay);
    }

    /**
     * Open modal with posts for current day
     *
     * @param {Date} date
     * @param {CalendarEvent[]} events
     */
    openPostsModal({ date, events }: { date: Date; events: CalendarEvent[] }, socialType?: string): void {
        const defaultModalFilters = socialType ? {platforms: [socialType]} : null;
        this.dialog
            .open(DialogPostsListComponent, {
                data: {
                    categories: this.categories,
                    date: date,
                    events: events,
                    socialSites: this.socialSites,
                    groupByParentID: true,
                    defaultFilters: defaultModalFilters,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((resetCalendar) => {
                if (resetCalendar) {
                    this.refreshPosts();
                }
            });
    }

    /**
     * Init social site component and get social sites
     */
    initSocialSites() {
        this.socialSiteController.getItems((response) => {
            this.socialSites = response.socialSites;
            this.socialSites = this.socialSites.filter(
                (item) =>
                    socialSiteAppearance?.[item.socialType]?.schedule !== false
            );
        });
    }

    presetFiltersByActiveTab(tabID: string, firstRun: boolean) {
        this.filterForm.first.form.controls["organizationID"].enable();
        this.filterForm.first.form.controls["dateToFormControl"].setValue(
            this.endDate
        );
        this.filterForm.first.form.controls["dateFromFormControl"].setValue(
            this.startDate
        );

        const map = {
            "#postCards": function () {
                this.filterForm.first.form.controls[
                    "orderByFormControl"
                ].enable();
                this.filterForm.first.form.controls[
                    "orderByFormControl"
                ].setValue("createDate-desc");
                this.filterForm.first.form.controls[
                    "searchValueFormControl"
                ].disable();
                this.filterForm.first.form.controls[
                    "tagsFormControl"
                ].disable();
                this.filterForm.first.form.controls[
                    "categoriesFormControl"
                ].enable();
                this.filterForm.first.form.controls[
                    "statusFormControl"
                ].enable();
                this.filterForm.first.form.controls[
                    "dateFromFormControl"
                ].enable();
                this.filterForm.first.form.controls[
                    "dateToFormControl"
                ].enable();
                this.filterForm.first.form.controls[
                    "definedDatesFormControl"
                ].enable();
            },
            "#calendar": function () {
                this.filterForm.first.form.controls[
                    "orderByFormControl"
                ].setValue(null);
                this.filterForm.first.form.controls[
                    "definedDatesFormControl"
                ].setValue(null);
                this.filterForm.first.form.controls[
                    "definedDatesFormControl"
                ].disable();
                this.filterForm.first.form.controls[
                    "tagsFormControl"
                ].disable();
                this.filterForm.first.form.controls[
                    "dateFromFormControl"
                ].disable();
                this.filterForm.first.form.controls[
                    "searchValueFormControl"
                ].disable();
                this.filterForm.first.form.controls[
                    "dateToFormControl"
                ].disable();
                this.filterForm.first.form.controls[
                    "categoriesFormControl"
                ].enable();
                this.filterForm.first.form.controls[
                    "statusFormControl"
                ].enable();
                this.filterForm.first.form.controls[
                    "orderByFormControl"
                ].disable();
            },
        };
        if (map.hasOwnProperty(tabID)) {
            map[tabID].call(this);

            if (tabID === "#postCards") {
                this.enableGroupPosts = true;
            }

            if (tabID === "#calendar") {
                this.enableGroupPosts = false;
            }

            this.filterForm.first.emitFilterChange();
        }
    }

    filtersChange(filters) {
        if (!this.wasPagination) {
            this.paginationController.paginationOptions.pageIndex = 0;

            this.filters["offset"] = 0;
        }

        /* tslint:disable */
        for (const filterName in filters) {
            let filter = filters[filterName];

            if (filterName === "order") {
                filter = this.getOrderFormat(filter);
            }

            if (filter !== null) {
                this.filters[filterName] = filter;
            } else {
                delete this.filters[filterName];
            }
        }
        /* tslint:enable */

        if (this.pagination) {
            this.setPagination({
                pageIndex: this.pagination.pageIndex,
                pageSize: this.pagination.pageSize,
            });
        }

        this.wasPagination = false;
        if(this.filtercount==0){
            // this.getItems();
            this.organizationController.service.getAll().then((response) => {
                const orgID = response.organizations[0].organizationID;
                // this.filterForm.get("organizationID").setValue([orgID]);
                this.filterForm.first.form.controls["organizationID"].setValue(
                    [response.organizations[0].organizationID]
                );
           });
            
        }else{
            this.getItems();
          
        }
        if (this.filterForm.first.form.controls["organizationID"].value.length) {
            this.activeFilter =true;
        } else {
            this.activeFilter =false;
        }
        this.filtercount++;
        // this.getItems();
    }

    resetFilter(tabID, firstRun) {
        this.resetForm.next(() => {
            this.presetFiltersByActiveTab(tabID, firstRun);
        });
    }

    setViewTab(tabID: string, firstRun: boolean) {
        this.onSocialPlatformsChange(
            this.socialPlatformService.platforms,
            true
        );
        if (tabID.indexOf("postCards") > -1) {
            // this.setPagination(null);
            this.filters = {
                ...this.defaultFilters,
                ...this.filters,
            };
        }

        // check navigate to calendar tab
        this.isCalendarView = tabID === "#calendar";

        // check navigate to post card tab
        this.isCardListView = tabID === "#postCards";

        this.resetFilter(tabID, firstRun);
    }

    getActiveTabId() {
        const elem = document.querySelector(".schedule-view-change a.active");
        if (elem) {
            return elem.getAttribute("href");
        }
        return undefined;
    }

    beforeGetItems() {
        super.beforeGetItems();

        // set calendar first and last day as date filters, when calendar view is active
        if (this.isCalendarView) {
            this.filters["activeFrom"] =
                DateHelper.convertSingleDateToStartEndDate(
                    this.startDate,
                    false
                );
            this.filters["activeFromTo"] =
                DateHelper.convertSingleDateToStartEndDate(this.endDate, true);
        }

        this.startPreloader();
    }

    afterDataLoad() {
        super.afterDataLoad();
        this._loadingRef.close();
    }

    setPagination(pagination) {
        if (this.getActiveTabId() === "#calendar") {
            delete this.filters["limit"];
            delete this.filters["offset"];

            return;
        }
    }

    /**
     * Másolva sajnos  a postlistből
     *
     * @param {PostInterface} post
     */
    copyPost(post: PostInterface) {
        this.openModal
            .copyPost(PostManagerComponent, post)
            .afterClosed()
            .subscribe((isChange) => {
                if (isChange) {
                    this.getPosts();
                }
            });
    }

    commentPostClick(post: PostInterface) {
        this.openModal.commentToPost(PostPreviewComponent, post);
    }

    editPostClick(post: PostInterface) {
        this.openModal
            .editPost(PostManagerComponent, post)
            .afterClosed()
            .subscribe((isChange) => {
                if (isChange) {
                    this.getPosts();
                }
            });
    }

    afterDeletePost(isSuccess: boolean, response: any) {
        super.afterDeletePost(isSuccess, response);

        if (isSuccess) {
            this.getPosts();
        }
    }

    onSocialPlatformsChange(socialPlatforms, isWithoutReload = false) {
        const activePlatforms = socialPlatforms
            .filter((platform) => platform.isActive)
            .map((platform) => platform.id);

        // remove all socialType in the filters
        forEach(this.filters, (filter, idx) => {
            if (idx.indexOf("socialType") > -1) {
                delete this.filters[idx];
            }
        });
        // add all socialTypes to the the filters
        forEach(activePlatforms, (platform, idx) => {
            this.filters["socialType[" + idx + "]"] = platform;
        });

        if (!isWithoutReload) {
            this.getPosts();
        }
    }

    private stopPreloader() {
        if (this._loadingRef) {
            this._loadingRef.close();
            this.isLoaderOpened = false;
        }
    }

    private startPreloader() {
        if (!this.isLoaderOpened) {
            this.isLoaderOpened = true;
            this._loadingRef = this.dialog.open(DialogLoaderComponent, {
                disableClose: true,
                panelClass: "dialog-loader-modal",
                minWidth: "200vw",
                minHeight: "200vh",
                hasBackdrop: false,
            });
        }
    }

    get languageService() {
        return LanguageService;
    }

    isDateSame(date): boolean {
        return moment(date).isSame(this.todaysDate, 'day');
    }

    isDateBefore(date): boolean {
        return moment(date).isBefore(this.todaysDate, 'day');
    }

    isDateAfter(date): boolean {
        return moment(date).isAfter(this.todaysDate, 'day');
    }

    filterToggleClicked() {
        if (!this.filterToggled) {
            this.filterClickEventsSubject.next();
        }
        this.filterToggled = true;
    }

    createTemplateFromPost(post: PostInterface) {
        post.activeFromInput = null;
        this.openModal.createTemplateRaw(PostTemplateManagerComponent, {
            data: {
                template: post,
                isTemplateFromPost: true,
            },
        });
    }

    @HostListener(`document:${CustomEvents.POST_OR_TEMPLATE_EDIT}`, ['$event'])
    getPostsOnPostEdit(): void {
        this.getPosts();
    }
}
